//
// Scaffolding
// --------------------------------------------------

@font-face {
  font-family: MyHistory;
  src: url(../fonts/a71d6c25f3ffa983.eot);
  src: url(../fonts/a71d6c25f3ffa983.eot?#iefix) format("embedded-opentype"),
    url(../fonts/a71d6c25f3ffa983.woff) format("woff"),
    url(../fonts/a71d6c25f3ffa983.ttf) format("truetype"),
    url(../fonts/a71d6c25f3ffa983.svg) format("svg");
}

html * {
  @include font-smoothing;
}
html,
body {
  height: 100%;
}
body {
  background: {
    position: center;
    color: $body-bg;
    repeat: no-repeat;
    size: cover;
    attachment: fixed;
  }
  color: $body-color;
  font: {
    family: $font-family-base;
    size: $font-size-md;
    weight: $font-weight-base;
  }
  line-height: $line-height-base;
  text-transform: $text-transform-base;
}

// Links
a {
  color: $link-color;
  text-decoration: underline;

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
}

// Small tag
.small,
small {
  font-size: 80%;
}

// Images
// Responsive images (ensure images don't scale beyond their parents)
img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
svg {
  max-width: 100%;
}

// Responsive iframes
iframe {
  width: 100%;
  border: 0;
}

// Thumbnails
.img-thumbnail {
  padding: 6px;
  border: 1px solid darken($border-color, 3%);
}

// Figures
.figure {
  position: relative;
  padding: 15px;
  background-color: $white;
  box-shadow: $box-shadow-card;
  > .badge {
    position: absolute;
    top: 25px;
    left: 25px;
    margin: 0;
    z-index: 5;
  }
}
.figure-img {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.figure-caption {
  font: {
    size: floor($font-size-base * 0.8125);
    weight: 600;
  }
}

// Horizontal rule
hr {
  margin: 0;
  border: 0;
  border-top: 1px solid $border-color;
  &.hr-light {
    border-top-color: $border-light-color;
  }
}

// Text Selection Color
::selection {
  background: $gray-darker;
  color: $white;
}
::-moz-selection {
  background: $gray-darker;
  color: $white;
}

// Bootstrap Overrides
@media (min-width: $screen-xl) {
  .container {
    max-width: 1170px;
  }
}
// @media (max-width: $screen-xl) {
//   .container {
//     width: 100% !important;
//     max-width: 100% !important;
//   }
// }
@media (min-width: $screen-xxl) {
  .container {
    max-width: 1410px;
  }
}
// @media (min-width: $screen-xxxl) {
//   .container {
//     max-width: 1570px;
//   }
// }
.container-fluid {
  max-width: 1920px;
  margin: {
    right: auto;
    left: auto;
  }
  padding: {
    right: 30px;
    left: 30px;
  }
  @media (max-width: $screen-xl) {
    padding: 0 15px;
  }
}

/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape),
  only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: landscape),
  only screen and (min-width: 414px) and (max-height: 736px) and (orientation: landscape) {
  body {
    font-size: 11px !important;
  }
  .video-hero {
    height: 140vh;
  }
  .video-wrp,
  .mouse-pointer-wrap,
  .video-full,
  .hr-line,
  .list-icon i {
    display: none !important;
  }
  .conf-title-wrp {
    margin-top: 0px !important;
    padding-top: 0px !important;

    .display-6.pt-md-5.pb-2 {
      padding-top: 0px !important;
    }
  }
  h1,
  h1.display-6 {
    font-size: 18px !important;

    // br{
    //   display: none;
    // }
  }
  .countdown {
    .countdown-value {
      font-size: 18px !important;
    }
  }
  .organizer {
    margin-top: -70px;

    h3 {
      font-size: 12px;
    }
  }
  .partners-logo-owl .owl-carousel .owl-item img {
    max-width: 100px !important;
  }
}

@media only screen and (min-width: 320px) and (max-height: 568px) and (orientation: portrait),
  only screen and (min-width: 375px) and (max-height: 667px) and (orientation: portrait),
  only screen and (min-width: 414px) and (max-height: 736px) and (orientation: portrait) {
  body {
    font-size: 11px !important;
  }
  .video-wrp,
  .mouse-pointer-wrap,
  .video-full,
  .hr-line,
  .list-icon i {
    display: none !important;
  }
  .organizer {
    margin-top: 0px !important;
    h3 {
      font-size: 12px;
    }
  }
  h1,
  h1.display-6 {
    font-size: 18px !important;
    padding-top: 40px;
  }
  .countdown {
    .countdown-value {
      font-size: 18px !important;
    }
  }
}
@media only screen and (min-width: 320px) and (max-height: 568px) and (orientation: portrait) {
  h1,
  h1.display-6 {
    font-size: 16px !important;
    padding-top: 30px;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 768px),
  only screen and (min-width: 1112px) and (max-height: 834px) {
  .video-wrp,
  .video-full {
    display: none !important;
  }
  h1,
  .h1 {
    font-size: 28px !important;
  }
}

.block-timeline {
  .table thead th,
  .table td,
  .table th {
    min-width: 110px;
    @media (max-width: $screen-sm) {
      min-width: auto;
    }
  }
}
.block-ogranisations {
  .text-center {
    align-self: center;
  }
}

// @media (device-width: 667px) and (device-height: 375px){
//   .video-hero{
//     height: 140vh;
//   }
//   .video-wrp, .mouse-pointer-wrap{
//     display: none;
//   }
//   h1, .h1{
//     font-size: 20px !important;
//   }
// }

:root {
  --headerOpacity: 1;
  --headerScale: 1;
}

@mixin coverer {
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
}

.video-header {
  position: relative;
  text-align: center;
  width: 100vw;
  height: 100vh;
  &,
  video,
  .viewport-header {
    @include coverer;
  }
  video {
    // background: gray;
    object-fit: cover;
    background-image: url("/static/img/hero_bg.jpg");
    background-size: cover;

    @media (max-width: $screen-sm) {
      background-image: url("/static/img/hero_bg_m_2.jpg");
      background-size: contain;
    }
  }
  .viewport-header {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    opacity: var(--headerOpacity);
    transform: scale(var(--headerScale));
    position: absolute;
    // background-color: #0005;
    background-image: url("/static/img/hero_sphere.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #0005;
    }
  }
  h1 {
    color: white;
    text-transform: uppercase;
    // letter-spacing: 2vw;
    line-height: 1.2;
    // font-size: 2.2vw;
    text-align: left;

    span {
      display: block;
      font-size: 9vw;
      // letter-spacing: 0.7vw;
    }
  }
}

.conf-title-wrp {
  display: flex;
  align-items: center;
}

.conf_title {
  font-size: 48px;
  text-align: left;
  margin-left: 40px;
  font-family: MyHistory;
  @media (max-width: $screen-sm) {
    font-size: 26px;
    margin-left: 20px;
  }
}

.conf-title {
  .h2 {
    font-weight: 400 !important;
    font-size: 36px;
    color: #fff;
    font-family: 'Rubik', sans-serif;

    @media (max-width: $screen-sm) {
      font-size: 22px;
    }

    a {
      color: #fff;
      font-size: 30px;
      background-color: #f7931e60;
      padding: 0 15px;
      text-decoration: none;

      @media (max-width: $screen-sm) {
        font-size: 18px;
      }
    }
  }
  @media (max-width: $screen-sm) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: end;
  }
}
.dateofconf {
  width: 140px;
  height: 140px;
  display: flex;
  vertical-align: middle;
  font-size: 14px;
  background-color: #0052ff60;
  color: #ffffff;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .day {
    font-size: 64px;
    line-height: 1;
  }

  @media (max-width: $screen-sm) {
    width: 90px;
    height: 90px;
    font-size: 10px;
    .day {
      font-size: 34px;
      line-height: 1;
    }
  }
}

.organizer {
  a:not(:last-child) {
    margin-right: 20px;
  }
  @media (max-width: $screen-sm) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 25px;
    a {
      margin-right: 0px !important;
      &:last-child {
        img {
          width: 65px !important;
        }
      }
    }
  }
}
