//
// Header
// --------------------------------------------------

// Site Logo
// --------------------------------------------------
.site-logo {
  display: block;
  margin-top: 10px;
  // width: $site-logo-width;
  width: 180px;
  color: $headings-color;
  text-decoration: none;
  position: relative;

  > img {
    display: block;
    width: 100%;
    margin-left: -35px;
  }
  @media (max-width: $screen-sm) {
    margin-left: 20px;
  }
}

.header{
  .site-logo {
    &::before{
      content: '';
      position: absolute;
      width: 172px;
      height: 84px;
      top: 32px;
      left: 160px;
      background: url('/static/img/logo/15n.svg');
      background-size: contain;
      background-repeat: no-repeat;
      overflow: hidden;

      @media (max-width: $screen-sm) {
        left: 80px !important;
        width: 100px;
        top: 12px;
      }
    }
  }
}

.navbar-stuck{
  .site-logo {
    &::before{
      top: -10px;
      height: 52px;
    }
  }
}

// Header Buttons
// --------------------------------------------------
.header-buttons {
  margin: 0;
  padding: 0;
  transition: box-shadow .3s;
  list-style: none;
  box-shadow: $box-shadow-header-btns;
  &::after {
    display: table;
    clear: both;
    content: '';
  }
  > li {
    display: block;
    position: relative;
    margin: 0;
    float: left;
    > a {
      display: block;
      width: ($btn-height-md + 8);
      height: $btn-height-md;
      transition: all .3s;
      border-right: 1px solid rgba($border-color, .6);
      color: $gray-darker;
      font-size: $font-size-xl;
      line-height: $btn-height-md;
      text: {
        align: center;
        decoration: none;
      }
    }
    &:last-child > a {
      width: ($btn-height-md + 7);
      padding-right: 3px;
      border-right: 0;
    }
    .badge {
      position: absolute;
      top: -4px;
      right: -6px;
    }
  }
  &:hover {
    box-shadow: none;
    > li > a { border-color: transparent; }
    > li:hover > a {
      box-shadow: $box-shadow-header-btns;
    }
  }
  > li > a:active {
    transition: all .1s;
    background-color: $gray-lighter;
    box-shadow: none !important;
  }
}

// Header / Footer Link
// --------------------------------------------------
.header-link,
.footer-link {
  display: inline-block;
  padding: 8px 0;
  transition: color .25s;
  color: rgba($gray-darker, .65);
  font-size: $font-size-sm;
  text-decoration: none;
  > i, > img {
    display: inline-block;
    vertical-align: middle;
  }
  > i {
    margin-right: 5px;
    font-size: 1.1em;
  }
  > img {
    width: 20px;
    margin: {
      top: -1px;
      right: 6px;
    }
  }
  &:hover { color: $gray-darker; }
}

// Header Dropdown Toggle
// --------------------------------------------------
.header-dropdown-toggle,
.header-mega-dropdown-toggle {
  position: relative;
  .dropdown-menu {
    margin: 0;
    &.right-aligned {
      right: 0;
      left: auto;
    }
  }
  &:hover {
    > .dropdown-menu {
      display: block;
      animation: dropdown-slide-down .3s;
    }
    .header-link {
      color: $gray-darker;
      opacity: 1;
    }
  }
}
.header-mega-dropdown-toggle { position: static !important; }

// Navbar Menu
// --------------------------------------------------
.navbar-nav {
  @extend %navbar-nav;
}

// Header Container
// --------------------------------------------------
.header {
  display: table;
  position: relative;
  width: 100%;
  // padding: 0 30px;
  border-bottom: 1px solid $border-color;
  background-color: transparent;
  z-index: 900;
  .header-branding,
  .header-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .header-wrapper {
    width: 100%;
    // padding-left: 30px;
  }
  .topbar {
    padding: 7px 0;
    background-color: #393835;
    color: #FFF;
    .dropdown-item { text-transform: none; }
    a{
      color: #FFF;
    }
  }
  .navbar {
    position: relative;
    padding: 5px 0;
  }
  // .navbar-nav > li.active > a::before { top: -18px; }
  .search-box { margin-top: -1px; }
  .social-btn {
    width: 28px;
    height: 28px;
    margin: {
      right: 7px;
      bottom: 0;
    }
    line-height: 28px;
  }
  @media (max-width: $screen-xl) {
    padding: 0 15px;
  }
  @media (max-width: $screen-lg) {
    .navbar { position: static; }
    .site-logo {
      width: ($site-logo-width - 20);
    }
    .header-wrapper { padding-left: 0; }
  }

  // Boxed / Floating Header
  &.header-boxed,
  &.header-floating {
    display: block;
    padding: 0;
    > .container { display: table; }
    .header-wrapper { padding-left: 20px; }
    // .navbar-nav > li.active > a::before { top: -17px; }
  }
  &.header-floating {
    position: absolute;
    top: 30px;
    left: 0;
    padding: 0 30px;
    border: 0;
    background: none;
    > .container {
      display: block;
      padding: 0 20px;
      background-color: $white;
      box-shadow: $box-shadow-card;
    }
    .search-box {
      height: calc(100% - 1px);
      margin-top: 0;
    }
    &.navbar-stuck > .container { box-shadow: none; }
    @media (max-width: $screen-lg) {
      position: relative;
      top: 0;
      padding: 0;
    }
  }
}

// Ghost Header
// --------------------------------------------------
.header-ghost {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 0 25px;
  z-index: 900;

  .container { position: relative; }

  .search-trigger,
  .menu-trigger {
    display: inline-block;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    transition: all .3s;
    color: $gray-dark;
    text-decoration: none;
    > i {
      display: inline-block;
      font-size: 21px;
      vertical-align: middle;
    }
    &:hover { color: $primary; }
    &.text-white:hover { opacity: .6; }
  }
  .search-trigger {
    left: 15px;
  }
  .menu-trigger {
    right: 15px;
    font: {
      size: $font-size-sm;
      weight: 600;
    }
    letter-spacing: $btn-letter-spacing;
    text-transform: uppercase;
    > span {
      display: inline-block;
      margin-top: 2px;
      vertical-align: middle;
    }
    > i { margin-left: 8px; }
  }
  @media (max-width: $screen-sm) {
    .site-logo { margin-left: -25px; }
  }
}

// Simple Ghost Header
.header-simple-ghost {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 0;
  background-color: transparent;
  .navbar { position: static; }
  .search-box {
    .search-close { right: 0; }
    &::before { left: 15px; }
    > .easy-autocomplete>input,
    > input {
      padding-left: 42px;
    }
  }
  .header-buttons {
    box-shadow: none;
    > li > a {
      border-right-color: rgba($white, .18);
      background-color: rgba($white, .1);
      color: $white;
      &:hover { background-color: rgba($white, .15); }
    }
  }
  .navbar-nav > li {
    > a { color: rgba($white, 1); }
    &:hover, &.active {
      > a {
        color: $white;
        // &::before {
        //   height: 2px;
        //   background-color: $white;
        // }
      }
    }
  }
}

// Sticky Navbar
// --------------------------------------------------
@media (max-width: $screen-lg) {
  body { padding-top: 0 !important; }
}
@media (min-width: $screen-lg) {
  .navbar-sticky.navbar-stuck {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    box-shadow: $box-shadow-base;
    animation: dropdown-slide-down .25s;
    .topbar { display: none !important; }
    &.header-boxed,
    &.header-floating { padding: 0; }
    &.header-floating > .container {
      padding: 0 15px;
    }
    &.header-ghost-white,
    &.header-simple-ghost { background-color: $gray-darker; }
  }
}
