//
// Typography
// --------------------------------------------------

// Headings
// -------------------------------------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  margin-bottom: floor($grid-vertical-step / 2); // ~12px
  color: $headings-color;
  font: {
    family: $font-family-headings;
    weight: $font-weight-headings;
    style: $font-style-headings;
  }
  letter-spacing: .021em;
  text-transform: $text-transform-headings;
  small {
    display: block;
    padding-top: 3px;
    color: $gray;
  }
}
h1, .h1 {
  font-size: $font-size-h1;
  line-height: $line-height-h1;
  @media (max-width: $screen-md) {
    font-size: floor($font-size-h1 * .8);
  }
  @media (max-width: $screen-sm) {
    font-size: floor($font-size-h1 * .6);
  }
}
h2, .h2 {
  font-size: $font-size-h2;
  line-height: $line-height-h2;
  @media (max-width: $screen-md) {
    font-size: floor($font-size-h2 * .85);
  }
}
h3, .h3 {
  font-size: $font-size-h3;
  line-height: $line-height-h3;
  @media (max-width: $screen-md) {
    font-size: floor($font-size-h3 * .9);
  }
}
h4, .h4 {
  font-size: $font-size-h4;
  line-height: $line-height-h4;
}
h5, .h5 {
  font-size: $font-size-h5;
  line-height: $line-height-h5;
}
h6, .h6 {
  font-size: $font-size-h6;
  line-height: $line-height-h6;
}

.display-1, .display-2,
.display-3, .display-4 {
  margin-bottom: floor($grid-vertical-step / 2); // ~12px
  font: {
    style: $font-style-display;
    weight: $font-weight-display;
  }
  line-height: $line-height-display;
  text-transform: $text-transform-display;
}
.display-1 { font-size: $display-1; }
.display-2 { font-size: $display-2; }
.display-3 { font-size: $display-3; }
.display-4 { font-size: $display-4; }
.display-404 {
  color: $white;
  font-size: $display-404;
  line-height: 1.12;
  text-shadow: 12px 12px 30px rgba($gray-darker, .13);
}
@media (max-width: $screen-lg) {
  .display-1 { font-size: floor($display-1 * .65); }
  .display-2 { font-size: floor($display-2 * .7); }
  .display-3 { font-size: floor($display-3 * .7); }
  .display-4 { font-size: floor($display-4 * .85); }
  .display-404 { font-size: floor($display-404 * .85); }
}
@media (max-width: $screen-md) {
  .display-404 { font-size: floor($display-404 * .6); }
}
@media (max-width: $screen-sm) {
  .display-404 { font-size: floor($display-404 * .4); }
}


// Body copy (paragraphs)
// -------------------------------------------------------

p, .p {
  margin: 0 0 floor($grid-vertical-step / 1.5); //~16px
}

// Predefined text sizes
.lead { font-size: $font-size-lead !important; }
.text-xl { font-size: $font-size-xl !important; }
.text-lg { font-size: $font-size-lg !important; }
.text-md { font-size: $font-size-md !important; }
.text-sm { font-size: $font-size-sm !important; }
.text-xs { font-size: $font-size-xs !important; }


// Special Links
// -------------------------

// Navi Link
.navi-link {
  transition: all .25s;
  color: $body-color;
  // font-weight: 600;
  text-decoration: none;
  > i {
    display: inline-block;
    margin-top: -1px;
    font-size: 1.2em;
    vertical-align: middle;
  }
  &:hover { color: $primary; }
  &.text-white:hover {
    opacity: .6;
  }
}

// Disabled Link
.navbar ul > li > a.disabled-link,
.mobile-menu ul > li > a.disabled-link,
.disabled-link {
  color: $gray !important;
  cursor: default;
  pointer-events: none;
  &:hover { color: $gray !important; }
}
.navbar ul > li:hover > a.disabled-link {
  color: $gray !important;
}


// Lists
// -------------------------

// Unordered and Ordered lists
ul, ol {
  margin: {
    top: 0;
    bottom: floor($grid-vertical-step / 1.5); // ~16px
  }
  padding-left: 18px;
  li {
    margin-bottom: floor($grid-vertical-step / 4); //~6px
    &:last-child { margin-bottom: 0; }
  }
  ul, ol { margin-bottom: 0; }
}

// List options
.list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled;
  > li {
    display: inline-block;
    margin-bottom: 0;
    padding: {
      right: 5px;
      left: 5px;
    }
  }
}

// Icon list
.list-icon {
  padding: 0;
  list-style: none;
  > li {
    position: relative;
    padding-left: 22px;
    > i {
      display: block;
      position: absolute;
      left: 0;
      font-size: 1.1em;
      line-height: inherit;
    }
  }
  &.lead > li { padding-left: 33px; }
  &.text-xl > li { padding-left: 26px; }
  &.text-lg > li { padding-left: 25px; }
  &.text-sm > li { padding-left: 18px; }
  &.text-xs > li { padding-left: 15px; }
}


// Blockquote
// -------------------------

.blockquote {
  display: block;
  position: relative;
  padding: 30px $grid-vertical-step 20px;
  background-color: $white;
  box-shadow: $box-shadow-base;
  &::before {
    display: block;
    position: absolute;
    top: -6px;
    left: $grid-vertical-step;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: rgba($primary, .1);
    color: $primary;
    font: {
      family: $font-family-headings;
      size: 30px;
    }
    line-height: 39px;
    text-align: center;
    content: '“';
  }
  > p { margin-bottom: 0; }
  .blockquote-footer {
    display: block;
    position: relative;
    margin-top: 12px;
    padding-top: 8px;
    font: {
      family: $font-family-headings;
      size: $font-size-md;
      weight: bold;
    }
    cite {
      display: block;
      color: $gray;
      font: {
        family: $font-family-base;
        size: $font-size-sm;
        weight: 600;
        style: normal;
      }
    }
    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 15px;
      height: 2px;
      background-color: $primary;
      content: '';
    }
  }
  &.text-right {
    &::before {
      right: $grid-vertical-step;
      left: auto;
    }
    .blockquote-footer::before {
      right: 0;
      left: auto;
    }
  }
  &.text-center {
    &::before {
      left: 50%;
      margin-left: -13px;
    }
    .blockquote-footer::before {
      left: 50%;
      margin-left: -8px;
    }
  }
}


// Description list
// -------------------------

dl {
  margin-top: 0; // Remove browser default
  margin-bottom: floor($grid-vertical-step / 1.5); // ~16px
}
dt, dd { line-height: $line-height-base; }
dt {
  margin-bottom: 6px;
  @extend h6;
  font-size: $font-size-lg;
}
dd {
  margin: {
    bottom: floor($grid-vertical-step / 1.5); // ~16px
    left: 0;
  }
  padding-bottom: floor($grid-vertical-step / 1.5); // ~16px
  border-bottom: 1px solid $border-color;
  color: $gray;
  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
}
.dl-with-icon {
  dt {
    position: relative;
    padding-left: 28px;
    > i {
      position: absolute;
      top: 4px;
      left: 0;
      font-size: 1.1em;
    }
  }
  dd { padding-left: 28px; }
}


// HTML tags
// -------------------------

// Pre tag
pre {
  display: block;
  padding: 15px;
  border: 1px solid $border-color;
  border-radius: $border-radius-base;
  background-color: $gray-lighter;
}

// KBD tag
kbd { background-color: $gray-dark; }


// Drop caps
// -------------------------

.drop-cap {
  &:first-child::first-letter {
    padding: {
      right: 8px;
      left: 4px;
    }
    float: left;
    font: {
      family: $font-family-headings;
      size: ceil($font-size-base * 3.75); //~60px
      weight: bold;
    }
    line-height: ceil($font-size-base * 3.75); //~60px
  }
  &.text-muted, &.text-dark, &.text-primary,
  &.text-accent, &.text-info, &.text-success,
  &.text-warning, &.text-danger {
    color: $body-color !important;
  }
  &.text-muted:first-child::first-letter { color: $gray !important; }
  &.text-dark:first-child::first-letter { color: $gray-darker !important; }
  &.text-primary:first-child::first-letter { color: $primary !important; }
  &.text-accent:first-child::first-letter { color: $accent !important; }
  &.text-info:first-child::first-letter { color: $info !important; }
  &.text-success:first-child::first-letter { color: $success !important; }
  &.text-warning:first-child::first-letter { color: $warning !important; }
  &.text-danger:first-child::first-letter { color: $danger !important; }
}
