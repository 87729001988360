//Import Google Fonts (Montserrat + Open Sans)
// @import url('https://fonts.googleapis.com/css?family=Montserrat:600,700,900|Open+Sans:400,600,700');
// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700&subset=cyrillic,cyrillic-ext');
@import url('https://fonts.googleapis.com/css?family=Rubik:400,700&subset=cyrillic');

// Helpers: Variables, Mixins and Placeholders
@import 'helpers/variables';
@import 'helpers/mixins';
@import 'helpers/placeholders';

// Base
@import 'base/scaffolding';
@import 'base/utilities';

// Components
@import 'components/typography';
@import 'components/code';
@import 'components/block-title';
@import 'components/forms';
@import 'components/input-group';
@import 'components/tables';
@import 'components/buttons';
@import 'components/social-buttons';
@import 'components/market-buttons';
@import 'components/navs';
@import 'components/card';
@import 'components/accordion';
@import 'components/pagination';
@import 'components/tooltip';
@import 'components/popover';
@import 'components/dropdown';
@import 'components/list-group';
@import 'components/alert';
@import 'components/toast-notification';
@import 'components/modal';
@import 'components/icon-box';
@import 'components/progress';
@import 'components/carousel';
@import 'components/gallery';
@import 'components/countdown';
@import 'components/animated-digits';
@import 'components/steps';
@import 'components/badge';
@import 'components/team';
@import 'components/testimonial';
@import 'components/pricing';
@import 'components/video-player';
@import 'components/widgets';
@import 'components/wizard';


// Layout
@import 'layout/grid';
@import 'layout/search';
@import 'layout/header';
@import 'layout/page-title';
@import 'layout/off-canvas';
@import 'layout/mobile-menu';
@import 'layout/fullscreen-overlay';

// Modules
@import 'modules/portfolio';
@import 'modules/blog';
@import 'modules/shop';

.video-full{
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (min-width: 2100px) {
    width: 100%;
  }
  @media (max-width: 2100px) {
    width: auto;
    height: 120vh;
  }
  @media (min-width:1920px) and (min-height:1080){
    width: 120%;
  }
  @media (device-aspect-ratio: 1/1){
    height: 120vh;
  }
  // @media (aspect-ratio: 1/1) {}
}

.video-hero{
  height: 100vh;
  // margin-top: -125px;
  // @media (max-width: $screen-sm) {
  //   height: auto;
  // }
}

.hero-rsk{
  background-image: url('/static/img/top-bg_15_1.jpg');
  background-size: cover;

  @media (max-width: $screen-md) {
    background-size: inherit;
  }

}

.partners-logo{
  .col-*{
    align-items: stretch;
  }
  .box-shadow{
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
  img{
    width: auto;
    max-height: 60px;
  }
}



.partners-logo-owl .owl-carousel .owl-item img{
  width: auto;
  max-height: 90px;
  max-width: 300px;
}

.conf-title{
  position: relative;
  z-index: 6;

  @media (min-width: $screen-sm) {
    top: 50%;
    transform: translateY(-50%);
  }
  .organizer{

    @media (min-width: $screen-sm) {
      margin-left: auto;
      text-align: right;
    }
  }

}

.mouse-pointer-wrap{
  position: absolute;
  width: 100%;
  z-index: 10;
  bottom: 60px;
  text-align: center;
  bottom: 0;
  height: 80px;

  .mouse-pointer{
    border: 2px solid #FFFFFF;
    border: 2px solid rgba(255, 255, 255, 1);
    height: 50px;
    width: 30px;
    border-radius: 20px;
    position: relative;
    margin: 0 auto;

    a{
      color: #FFF;
      text-decoration: none;
      line-height: 60px;
    }
  }
}

.owl-carousel.owl-partners .owl-item img{
  width: auto;
  height: 50px;
}
